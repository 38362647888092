<template>
  <div>
    <b-modal size="xl" hide-footer v-model="modal_box_summary" class="w-100" title="Resumen de Caja">
        <b-tabs align="center" content-class="mt-3">
          <b-tab title="Cobros" active>
            <div class="table-responsive mt-3 height-table">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="3%" class="text-center">#</th>
                    <th width="10%" class="text-center">Fecha</th>
                    <th width="12%" class="text-center">Documento</th>
                    <th width="42%" class="text-center">Cliente</th>
                    <th width="18%" class="text-center">Medio de pago</th>
                    <th width="7%" class="text-center">Moneda</th>
                    <th width="8%" class="text-center">Total</th>
                  </tr>
                </thead>
                <tbody v-for="(item, it) in charges" :key="it">
                  <tr>
                    <td class="text-center">{{ it + 1 }}</td>
                    <td class="text-center"> {{ item.broadcast_date }}</td>
                    <td class="text-center"> {{ item.document }}</td>
                    <td class="text-left"> {{ item.client_name + ' - '+item.client_document_number }}</td>
                    <td class="text-left"> {{ NameMethodPayment(item.payment_method) }}</td>
                    <td class="text-center"> {{ item.coin}} </td>
                    <td class="text-right"> {{ item.total}} </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-row class="mt-4">
              <b-col md="8">
                <b-pagination v-model="charge_currentPage"  v-on:input="ListChargeSummary"  :total-rows="charge_rows"  :per-page="charge_perPage" align="center" ></b-pagination>
              </b-col>
              <b-col md="4 text-center">
                <p>Pagina Actual: {{ charge_currentPage }}</p>
              </b-col>
            </b-row>
          </b-tab>


          <!-- <b-tab title="Pagos">
            <div class="table-responsive mt-3 height-table">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="3%" class="text-center">#</th>
                    <th width="10%" class="text-center">Fecha</th>
                    <th width="10%" class="text-center">Documento</th>
                    <th width="42%" class="text-center">Proveedor</th>
                    <th width="18%" class="text-center">Medio de pago</th>
                    <th width="7%" class="text-center">Moneda</th>
                    <th width="8%" class="text-center">Total</th>
                  </tr>
                </thead>
                <tbody v-for="(item, it) in payments" :key="it">
                  <tr>
                    <td class="text-center">{{ it + 1 }}</td>
                    <td class="text-center"> {{ item.broadcast_date }}</td>
                    <td class="text-center"> {{ item.document }}</td>
                    <td class="text-left"> {{ item.provider_name + ' - '+item.provider_document_number }}</td>
                    <td class="text-left"> {{ NameMethodPayment(item.payment_method) }}</td>
                    <td class="text-center"> {{ item.coin}} </td>
                    <td class="text-right"> {{ item.total}} </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-row class="mt-4">
              <b-col md="8">
                <b-pagination v-model="payment_currentPage"  v-on:input="ListPaymentSummary"  :total-rows="payment_rows"  :per-page="payment_perPage" align="center" ></b-pagination>
              </b-col>
              <b-col md="4 text-center">
                <p>Pagina Actual: {{ payment_currentPage }}</p>
              </b-col>
            </b-row>
          </b-tab> -->


          <b-tab title="Cobros de Cuentas">
            <div class="table-responsive mt-3 height-table">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="3%" class="text-center">#</th>
                    <th width="10%" class="text-center">Fecha</th>
                    <th width="10%" class="text-center">Documento</th>
                    <th width="42%" class="text-center">Proveedor</th>
                    <th width="18%" class="text-center">Medio de pago</th>
                    <th width="7%" class="text-center">Moneda</th>
                    <th width="8%" class="text-center">Total</th>
                  </tr>
                </thead>
                <tbody v-for="(item, it) in account_charges" :key="it">
                  <tr>
                    <td class="text-center">{{ it + 1 }}</td>
                    <td class="text-center"> {{ item.broadcast_date }}</td>
                    <td class="text-center"> {{ item.document }}</td>
                    <td class="text-left"> {{ item.client_name + ' - '+item.client_document_number }}</td>
                    <td class="text-left"> {{ NameMethodPayment(item.payment_method) }}</td>
                    <td class="text-center"> {{ item.coin}} </td>
                    <td class="text-right"> {{ item.total}} </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-row class="mt-4">
              <b-col md="8">
                <b-pagination v-model="account_charge_currentPage"  v-on:input="ListAccountChargeSummary"  :total-rows="account_charge_rows"  :per-page="account_charge_perPage" align="center" ></b-pagination>
              </b-col>
              <b-col md="4 text-center">
                <p>Pagina Actual: {{ account_charge_currentPage }}</p>
              </b-col>
            </b-row>
          </b-tab>



          <b-tab title="Pagos de Cuentas">
            <div class="table-responsive mt-3 height-table">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="3%" class="text-center">#</th>
                    <th width="10%" class="text-center">Fecha</th>
                    <th width="10%" class="text-center">Documento</th>
                    <th width="42%" class="text-center">Proveedor</th>
                    <th width="18%" class="text-center">Medio de pago</th>
                    <th width="7%" class="text-center">Moneda</th>
                    <th width="8%" class="text-center">Total</th>
                  </tr>
                </thead>
                <tbody v-for="(item, it) in account_payments" :key="it">
                  <tr>
                    <td class="text-center">{{ it + 1 }}</td>
                    <td class="text-center"> {{ item.broadcast_date }}</td>
                    <td class="text-center"> {{ item.document }}</td>
                    <td class="text-left"> {{ item.provider_name + ' - '+item.provider_document_number }}</td>
                    <td class="text-left"> {{ NameMethodPayment(item.payment_method) }}</td>
                    <td class="text-center"> {{ item.coin}} </td>
                    <td class="text-right"> {{ item.total}} </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-row class="mt-4">
              <b-col md="8">
                <b-pagination v-model="account_payment_currentPage"  v-on:input="ListAccountPaymentSummary"  :total-rows="account_payment_rows"  :per-page="account_payment_perPage" align="center" ></b-pagination>
              </b-col>
              <b-col md="4 text-center">
                <p>Pagina Actual: {{ account_payment_currentPage }}</p>
              </b-col>
            </b-row>
          </b-tab>


          <b-tab title="Ingresos Extraordinarios">
            <div class="table-responsive mt-3 height-table">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="3%" class="text-center">#</th>
                    <th width="10%" class="text-center">Fecha</th>
                    <th width="10%" class="text-center">Documento</th>
                    <th width="42%" class="text-center">Proveedor</th>
                    <th width="18%" class="text-center">Medio de pago</th>
                    <th width="7%" class="text-center">Moneda</th>
                    <th width="8%" class="text-center">Total</th>
                  </tr>
                </thead>
                <tbody v-for="(item, it) in extraordinary_incomes" :key="it">
                  <tr>
                    <td class="text-center">{{ it + 1 }}</td>
                    <td class="text-center"> {{ item.broadcast_date }}</td>
                    <td class="text-center"> {{ item.document }}</td>
                    <td class="text-left"> {{ item.client_name + ' - '+item.client_document_number }}</td>
                    <td class="text-left"> {{ NameMethodPayment(item.payment_method) }}</td>
                    <td class="text-center"> {{ item.coin}} </td>
                    <td class="text-right"> {{ item.total}} </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-row class="mt-4">
              <b-col md="8">
                <b-pagination v-model="extraordinary_income_currentPage"  v-on:input="ListExtraordinaryIncomeSummary"  :total-rows="extraordinary_income_rows"  :per-page="extraordinary_income_perPage" align="center" ></b-pagination>
              </b-col>
              <b-col md="4 text-center">
                <p>Pagina Actual: {{ extraordinary_income_currentPage }}</p>
              </b-col>
            </b-row>
          </b-tab>


          <b-tab title="Egresos Extraordinarios">
            <div class="table-responsive mt-3 height-table">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="3%" class="text-center">#</th>
                    <th width="10%" class="text-center">Fecha</th>
                    <th width="10%" class="text-center">Documento</th>
                    <th width="42%" class="text-center">Proveedor</th>
                    <th width="18%" class="text-center">Medio de pago</th>
                    <th width="7%" class="text-center">Moneda</th>
                    <th width="8%" class="text-center">Total</th>
                  </tr>
                </thead>
                <tbody v-for="(item, it) in extraordinary_expenses" :key="it">
                  <tr>
                    <td class="text-center">{{ it + 1 }}</td>
                    <td class="text-center"> {{ item.broadcast_date }}</td>
                    <td class="text-center"> {{ item.document }}</td>
                    <td class="text-left"> {{ item.provider_name + ' - '+item.provider_document_number }}</td>
                    <td class="text-left"> {{ NameMethodPayment(item.payment_method) }}</td>
                    <td class="text-center"> {{ item.coin}} </td>
                    <td class="text-right"> {{ item.total}} </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-row class="mt-4">
              <b-col md="8">
                <b-pagination v-model="extraordinary_expense_currentPage"  v-on:input="ListExtraordinaryExpenseSummary"  :total-rows="extraordinary_expense_rows"  :per-page="extraordinary_expense_perPage" align="center" ></b-pagination>
              </b-col>
              <b-col md="4 text-center">
                <p>Pagina Actual: {{ extraordinary_expense_currentPage }}</p>
              </b-col>
            </b-row>
          </b-tab>


        </b-tabs>
    </b-modal>

    <LoadingComponent :is-visible="isLoading"/>
 
  </div>
</template>
<style>



</style>
<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
import CodeToName from "@/assets/js/CodeToName";
import LoadingComponent from './../../pages/Loading'
var moment = require("moment");



export default {
  name: "ModalsProduct",
  components:{
    LoadingComponent,

  },
  data() {
    return {
        isLoading: false,
        modal_box_summary:false,
        module:'Box',
        role:'',
        id_box:'',
        

        charge_perPage: 15,
        charge_currentPage: 1,
        charge_rows: 0,
        charges: [],

        payment_perPage: 15,
        payment_currentPage: 1,
        payment_rows: 0,
        payments: [],

        account_charge_perPage: 15,
        account_charge_currentPage: 1,
        account_charge_rows: 0,
        account_charges: [],

        account_payment_perPage: 15,
        account_payment_currentPage: 1,
        account_payment_rows: 0,
        account_payments: [],

       extraordinary_expense_perPage: 15,
       extraordinary_expense_currentPage: 1,
       extraordinary_expense_rows: 0,
       extraordinary_expenses: [],

       extraordinary_income_perPage: 15,
       extraordinary_income_currentPage: 1,
       extraordinary_income_rows: 0,
       extraordinary_incomes: [],
    
    };
  },
  created (){
    
  },
  mounted () {
    EventBus.$on('ModalBoxSummaryShow', (data) => {
      this.role = data.role;
      this.id_box = data.id_box;
      this.modal_box_summary = true;
      this.ListChargeSummary();
      this.ListPaymentSummary();
      this.ListAccountChargeSummary();
      this.ListAccountPaymentSummary();
      this.ListExtraordinaryIncomeSummary();
      this.ListExtraordinaryExpenseSummary();
    });
    
  },
  methods: {
      NameMethodPayment,

      ListChargeSummary,
      ListPaymentSummary,
      ListAccountChargeSummary,
      ListAccountPaymentSummary,

      ListExtraordinaryIncomeSummary,
      ListExtraordinaryExpenseSummary,
  },
  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};

function NameMethodPayment(code) {
  return CodeToName.NameMethodPayment(code);
}

function ListChargeSummary() {
  let me = this;
  let url = me.url_base + "box/charge-summary/"+me.id_box+ "?page=" + this.charge_currentPage;
  axios({
    method: "GET",
    url:url,
    headers: { token: me.token, module: me.module, role: me.role},
  })
  .then(function (response) {
    if (response.data.status == 200) {
        me.charge_rows = response.data.result.total;
        me.charges = response.data.result.data;
    }
  })
  .catch((error) => {
  });
}


function ListPaymentSummary() {
  let me = this;
  let url = me.url_base + "box/payment-summary/"+me.id_box+ "?page=" + this.payment_currentPage;
  axios({
    method: "GET",
    url:url,
    headers: { token: me.token, module: me.module, role: me.role},
  })
  .then(function (response) {
    if (response.data.status == 200) {
        me.payment_rows = response.data.result.total;
        me.payments = response.data.result.data;
    }
  })
  .catch((error) => {
  });
}


function ListAccountChargeSummary() {
  let me = this;
  let url = me.url_base + "box/account-charge-summary/"+me.id_box+ "?page=" + this.account_charge_currentPage;
  axios({
    method: "GET",
    url:url,
    headers: { token: me.token, module: me.module, role: me.role},
  })
  .then(function (response) {
    if (response.data.status == 200) {
        me.account_charge_rows = response.data.result.total;
        me.account_charges = response.data.result.data;
    }
  })
  .catch((error) => {
  });
}


function ListAccountPaymentSummary() {
  let me = this;
  let url = me.url_base + "box/account-payment-summary/"+me.id_box+ "?page=" + this.account_payment_currentPage;
  axios({
    method: "GET",
    url:url,
    headers: { token: me.token, module: me.module, role: me.role},
  })
  .then(function (response) {
    if (response.data.status == 200) {
        me.account_payment_rows = response.data.result.total;
        me.account_payments = response.data.result.data;
    }
  })
  .catch((error) => {
  });
}



function ListExtraordinaryIncomeSummary() {
  let me = this;
  let url = me.url_base + "box/extraordinary-income-summary/"+me.id_box+ "?page=" + this.extraordinary_income_currentPage;
  axios({
    method: "GET",
    url:url,
    headers: { token: me.token, module: me.module, role: me.role},
  })
  .then(function (response) {
    if (response.data.status == 200) {
        me.extraordinary_income_rows = response.data.result.total;
        me.extraordinary_incomes = response.data.result.data;
    }
  })
  .catch((error) => {
  });
}


function ListExtraordinaryExpenseSummary() {
  let me = this;
  let url = me.url_base + "box/extraordinary-expense-summary/"+me.id_box+ "?page=" + this.extraordinary_expense_currentPage;
  axios({
    method: "GET",
    url:url,
    headers: { token: me.token, module: me.module, role: me.role},
  })
  .then(function (response) {
    if (response.data.status == 200) {
        me.extraordinary_expense_rows = response.data.result.total;
        me.extraordinary_expenses = response.data.result.data;
    }
  })
  .catch((error) => {
  });
}




</script>
